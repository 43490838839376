$container-max-widths: (sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1040px,
    xxl: 1320px);

$font-size-base: 1.2rem;

@import "../node_modules/animate.css/animate";

@import "../node_modules/bootstrap/scss/bootstrap";

html {
    background-color: #000000;

    body {
        &.loadAnimation {
            opacity: 0;
            transition: all 1s;
        }

        transition: all 1s;
        font-family: 'Raleway',
        sans-serif;
        font-weight: lighter;

        section {
            padding: 85px 0;
        }

        .text-light {
            color: #b2aca2 !important;
        }

        hr {
            width: 100px;
            height: 4px;
            background-color: #5e7b2d;
            margin: 0 auto;
            opacity: 1;
        }

        @for $i from 1 through 6 {
            h#{$i} {
                font-weight: lighter;
            }
        }

        .navbar {
            ul {
                li {
                    a {
                        font-size: 14px;
                        line-height: 14px;
                        text-transform: uppercase;
                        color: #b2aca2;
                    }
                }
            }
        }

        .slider {
            padding: 0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-blend-mode: multiply;
            background-color: rgba($color: #000000, $alpha: 0.45);

            .row {
                height: calc(100vh - 56px);

                h1 {
                    font-size: 3.8rem;
                    font-weight: lighter;
                    margin-bottom: 20px;
                }
            }
        }

        .copertina {
            padding: 0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-blend-mode: multiply;
            background-color: rgba($color: #000000, $alpha: 0.45);

            .row {
                height: calc(80vh - 56px);

                h1 {
                    font-size: 3.5rem;
                    font-weight: lighter;
                    margin-bottom: 20px;
                }
            }
        }

        .news {
            padding: 0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-blend-mode: multiply;
            background-color: rgba($color: #000000, $alpha: 0.45);

            .row {
                height: calc(100vh);

                h1 {
                    font-size: 3.5rem;
                    font-weight: lighter;
                    margin-bottom: 20px;
                }
            }
        }

        .aspect-ratio-square {
            aspect-ratio: 1/1;
        }

        .object-fit-cover {
            object-fit: cover;
            object-position: center;
        }
    }
}

/* raleway-regular - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/raleway/raleway-v26-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/raleway/raleway-v26-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/raleway/raleway-v26-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/raleway/raleway-v26-latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/raleway/raleway-v26-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/raleway/raleway-v26-latin-regular.svg#Raleway') format('svg');
    /* Legacy iOS */
}

/* raleway-700 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/raleway/raleway-v26-latin-700.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/raleway/raleway-v26-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/raleway/raleway-v26-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/raleway/raleway-v26-latin-700.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/raleway/raleway-v26-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/raleway/raleway-v26-latin-700.svg#Raleway') format('svg');
    /* Legacy iOS */
}



@import "./fancybox";